.login {
  height: 100%;
  width: 100%;

  .row {
    --bs-gutter-x: unset;
  }

  .login__panel {
    &.login--left {
      background: $white;
      background-size: cover;
      height: 100%;
      position: relative;
    }

    &.login--right {
      align-items: center;
      background: $brand-primary-0;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      .login__title {
        color: $white;
        position: relative;
        text-align: center;

        h1 {
          font-weight: bold;
          position: relative;
          z-index: 1;
        }

        p {
          color: $white;
        }

        .login__gridbox {
          opacity: 0.1;
          position: absolute;

          &.login--bottom {
            left: -31px;
            top: -20px;
            z-index: 0;
          }

          &.login--top {
            right: -30px;
            top: -60px;
          }
        }
      }

      .login__form {
        margin: 3em 0 1em;
        max-width: 300px;
        padding: 0 $base-size;
        width: 100%;
      }

      .login__buttons {
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        margin: 3em 0 1em;
        max-width: 300px;
        padding: 0 $base-size;
        width: 100%;

        [type="button"] {
          -webkit-appearance: none;
          background: transparent;
          padding: 0;
        }
      }
    }
  }

  &__logo {
    &.login--idsc {
      left: 40px;
      position: absolute;
      top: 40px;

      img {
        width: 4rem;
        height: 4rem;
      }
    }

    &.login--umseshi {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }

  .error-message {
    color: $red;
  }

  @media (max-width: 1980px) {
    &__image {
      height: 55%;
    }
  }

  @media (max-width: 992px) {
    &__image {
      height: 40%;
    }
  }

  @media (max-width: 768px) {
    &__image {
      height: 40%;
    }
  }

  @media (max-width: 576px) {
    &__image {
      display: none;
    }
  }
}
