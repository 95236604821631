// FORM-GROUP

.form {
  &__header {
    align-items: center;
    color: $gray-9;
  }

  // This is for model parameters

  &--model-parameter {
    position: relative;

    .form {
      height: 100%;

      // Form section will have a scroll use for body content

      &__section {
        height: 20rem;
        margin-bottom: 0;
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0;

        // position

        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.form-group {
  margin-bottom: 2 * $base-size;
  position: relative;
  align-items: center;

  // Label and form-control inline modifier

  &__icon {
    &--flex {
      position: absolute;
      width: auto;
      bottom: 4px;
      right: 10px;
      margin-top: 0;

      i.fa {
        color: $gray-2;
      }

      &:hover {
        i.fa {
          color: $black-1;
        }
      }
    }

    &--inline {
      position: absolute;
      width: auto;
      bottom: 11px;
      right: 23px;
      margin-top: 0;

      i.fa {
        color: $gray-2;
      }

      &:hover {
        i.fa {
          color: $black-1;
        }
      }
    }
  }

  &--inline {
    display: grid;
    gap: 0.75rem;
    align-items: center;
    grid-template-columns: 0.1fr 1fr;
    margin-bottom: 0 !important;

    label {
      white-space: nowrap;
      margin-bottom: 0;
    }
  }

  &--flex {
    display: flex;
    grid-gap: 0.75rem;
    align-items: center;
    justify-content: center;

    label {
      margin-bottom: 0;
      white-space: nowrap;
    }
  }

  // Size modifier

  &--sm {
    .form-control {
      width: 4rem;
    }
  }

  &--xsm {
    .form-control {
      width: 7rem;
    }
  }

  &--lg {
    .form-control {
      width: 12rem;
    }
  }

  .error-message {
    color: $red;
    font-size: 0.6875 * $base-size;
    margin-top: 0.25 * $base-size;

    i.fas {
      margin-right: 0.5 * $base-size;
    }
  }

  .warning-feedback {
    color: $orange;
    font-size: 0.875 * $base-size;

    i {
      color: $orange;
      height: 6px;
      width: 6px;
    }
  }

  label {
    color: $gray-9;
    font-size: 0.875 * $base-size;
    font-weight: normal;
    display: inline-block;
    align-items: center;
    white-space: nowrap;

    i {
      color: $gray-10;
      font-size: $base-size;
      align-self: flex-end;
    }
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
  }
}

// FORMS

.form-control {
  border-color: $gray-5 !important;
  font-size: 0.875rem !important;
  height: 2.5rem !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  position: relative;

  // ERROR-STATE INPUT

  &.is-invalid {
    border-color: $red !important;
  }

  // WARNING-STATE INPUT

  &.is-warning {
    border-color: $orange !important;
  }

  &::placeholder {
    color: $gray-6 !important;
  }

  // INPUT HIGHLIGHT

  &:focus {
    border-color: $gray-6 !important;
    box-shadow: none !important;
    outline: 0 none !important;
  }

  &:disabled,
  &[readonly] {
    background-color: $gray-2 !important;
    color: $gray-6 !important;
  }
}

// FORMS

.form-textarea {
  border-color: $gray-5 !important;
  font-size: 0.875rem !important;
  height: 7.5rem !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  position: relative;

  // ERROR-STATE INPUT

  &.is-invalid {
    border-color: $red !important;
  }

  // WARNING-STATE INPUT

  &.is-warning {
    border-color: $orange !important;
  }

  &::placeholder {
    color: $gray-6 !important;
  }

  // INPUT HIGHLIGHT

  &:focus {
    border-color: $gray-6 !important;
    box-shadow: none !important;
    outline: 0 none !important;
  }

  &:disabled,
  &[readonly] {
    background-color: $gray-2 !important;
    color: $gray-6 !important;
  }
}

// SEARCH FIELD

.search-group {
  position: relative;
  width: 35%;

  input {
    width: -webkit-fill-available;
  }

  button {
    align-items: center;
    background-color: $brand-primary-0;
    border: 0;
    border-radius: 5px;
    color: $white;
    display: flex;
    height: 1.875 * $base-size;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 0.4 * $base-size;
    top: 0.3 * $base-size;
    width: 2 * $base-size;

    i {
      font-size: 0.65 * $base-size;
    }
  }
}

// PARAGRAPH

p {
  color: $gray-9;
  letter-spacing: 0.4px;
  line-height: 1.7;
}

select option:hover,
select option:focus,
select option:active {
  background-color: $brand-primary-0;
}

.label-with-description {
  margin-bottom: 0.375 * $base-size;
}

.form-description {
  color: $brand-primary-0;
  font-size: 0.75 * $base-size;
  margin-bottom: 0.5 * $base-size;

  // &__footer {
  //   align-items: center;
  //   background-color: $white;
  //   bottom: 0;
  //   display: flex;
  //   justify-content: flex-end;
  //   margin-left: -2 * $base-size;
  //   margin-right: -2 * $base-size;
  //   padding: 0.5 * $base-size 2 * $base-size;
  //   position: fixed;
  //   width: 100%;
  //   z-index: 999;

  //   &__btn {
  //     gap: 5px;
  //     margin-bottom: $base-size;
  //   }

  //   .btn-outline {
  //     margin-right: 0.25 * $base-size;
  //   }
  // }

  // &__section {
  //   align-items: center;
  //   display: flex;
  //   justify-content: space-between;
  //   margin-top: 2 * $base-size;
  // }

  &__checkbox {
    height: $base-size !important;
    outline: $brand-primary-0 !important;
    width: $base-size !important;
  }

  &__section {
    margin-bottom: 1.25 * $base-size;
    color: $gray-9;
  }
}

// TEXTAREA

textarea {
  height: 10rem !important;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
