.filter-group {
  margin-left: -1rem;

  .filter-title {
    margin-left: -1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.7rem;
  }

  :host ::ng-deep .ng-select-container {
    margin-left: -1rem;
  }

  .filter-button {
    margin-top: -0.5rem;
  }

  .filter-parent {
    display: flex;
    margin-right: 2rem;
    min-width: calc(20% - 8rem);
  }
}

.page {
  &--report {
    overflow-y: auto;

    .page__header {
      display: flex;
      justify-content: space-between;

      &__left {
        label {
          margin-bottom: 0;
        }

        .page__title {
          margin-bottom: 0;
        }
      }

      &__right {
        display: flex;

        .action-group {
          display: flex;
          align-items: flex-end;
        }
      }
    }

    .page__body {
      &--report {
        height: calc(100% - 4rem);
        overflow: auto;
      }

      .report-title {
        text-align: center;
        color: $brand-primary-0;
      }
    }
  }
}
