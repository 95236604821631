// Changed default feather icon size to 20px

i-feather {
  width: 1.429 * $base-size !important;
  height: 1.429 * $base-size !important;

  // all span beside a feather icon will have a margin-left of .5rem or 7px
}

i-feather.edit {
  height: 14px !important;
  width: 14px !important;
}

i-feather ~ span {
  margin-left: 0.5rem;
}

/* -----
SVG Icons - svgicons.sparkk.fr
----- */

.svg-icon {
  width: 24px;
  height: 24px;

  &--circle {
    fill: $red;
  }
}

.icon {
  // Icon colors

  &-primary {
    color: $brand-primary-0;
  }

  &-green {
    color: $green;
  }

  &-red {
    color: $red;
  }

  &-gray {
    color: $gray-11;
  }

  &-orange {
    color: $orange;
  }
}

.bx {
  &.bxl-html5, &.bxl-firebase {
    color: $orange;
    background-color: $white;
  }

  &.bxl-typescript, &.bxl-css3 {
    color: $blue;
    background-color: $white;
  }

  &.bxl-javascript {
    color: $yellow;
    background-color: $black;
  }

  &.bxl-angular {
    color: $red;
    background-color: $white;
  }

  &.bxl-bootstrap {
    color: $purple;
    background-color: $white;
  }

  &.bxs-data {
    color: $yellow;
    background-color: $white;
  }

  &.bx-data {
    color: $gray-6;
    background-color: $white;
  }

  &.bxl-git {
    color: $red-orange;
    background-color: $white;
  }
}

i.skill-icon {
  margin-bottom: 0.25rem;
  font-size: 40px;
}

// Current image size is 24x24
img.skill-icon {
  height: 2rem;
  width: 2rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 40px;
}

i.project-icon {
  margin-bottom: 0.25rem;
  font-size: 30px;
}

// Current image size is 24x24
img.project-icon {
  height: 1.75rem;
  width: 1.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 40px;
}

.skill-type-group {
  margin: 2rem 0;
  text-align: center;
}

.skill-type-icon {
  i {
    margin-right: 0.5rem;
  }

  i::before {
    vertical-align: middle;
  }
}

.icon-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.contact-icon {
  color: $blue;
  
  .bg-icon {
    min-width: 2.5rem;
    min-height: 2.5rem;
    background-color: $gray-4;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }

  i {
    font-size: 25px !important;
  }

  h5 {
    margin: 0;
  }
}