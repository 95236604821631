// SIDE-NAVIGATION
// .side-nav {
//   overflow-y: auto;
//   border-right: 1px solid $gray-2;
//   padding-top: 1.25 * $base-size;
//   padding-left: 2 * $base-size;
//   padding-right: 2 * $base-size;
//   padding-bottom: 2 * $base-size;
//   height: 100%;
//   flex-direction: column;
//   display: flex;
//   position: static;
//   background: $white;
//   min-width: 15.8125 * $base-size;

//   &--hidden {
//     position: static;
//   }

//   &--sm-expanded {
//     position: fixed;
//     z-index: 1000;
//   }

//   &__logo {
//     margin: 0;
//     margin-top: 2.25 * $base-size;
//     display: block;
//     padding: 0;
//     font-size: 0;
//     text-align: left;
//     height: 40px;
//     border-bottom: #e6e6e6;

//     img {
//       height: 2.9375 * $base-size;
//     }
//   }

//   &__section {
//     margin-top: 0.5 * $base-size;

//     .side-nav__list-items {
//       list-style: none;
//       padding: 0;

//       li {
//         height: 2.8125 * $base-size;
//         margin-top: 0.5 * $base-size;
//         margin-bottom: 0.5 * $base-size;

//         a {
//           height: 100%;
//           display: flex;
//           align-items: center;
//           text-decoration: none;
//           padding-left: $base-size;
//           padding-right: $base-size;
//           font-weight: 600;
//           font-size: 0.75 * $base-size;
//           letter-spacing: 1px;
//           color: rgba($gray-1, 0.8) !important;
//           transition: all 0.2s ease-in-out;

//           &:hover {
//             color: $brand-primary-0 !important;
//           }

//           i.fa,
//           i.fas,
//           i.far,
//           i.fab {
//             width: 1.375 * $base-size;
//             height: 1.375 * $base-size;
//             margin-right: 0.5 * $base-size;
//             opacity: 0.6;
//             display: flex;
//             align-items: center;
//           }

//           &:hover {
//             path {
//               fill: $brand-primary-0;
//               opacity: 1;
//             }

//             g {
//               opacity: 1;
//             }
//           }

//           &:active,
//           &.active {
//             color: $white !important;
//             background-color: rgba($brand-primary-0, 0.7);
//             border-radius: 4px;
//             font-weight: bold;
//             transition: all 0.2s ease-in-out;

//             path {
//               fill: $brand-primary-0;
//               opacity: 1;
//             }

//             g {
//               opacity: 1;
//             }
//           }
//         }
//       }
//     }
//   }

//   // NGBACCORDION
//   &__user-panel {
//     display: flex;
//     align-items: center;
//     width: 100%;
//     margin-top: auto;

//     &__thumbnail,
//     &__action-centre {
//       align-items: center;
//       background-color: $white;
//       border: 0;
//       border-radius: 50px;
//       display: flex;
//       height: 2.5 * $base-size;
//       justify-content: center;
//       margin-right: 0.875 * $base-size;
//       position: relative;
//       width: 2.5 * $base-size;
//     }

//     &__thumbnail {
//       overflow: hidden;

//       img {
//         height: 100%;
//         width: 100%;
//         transform: scale(1.5);
//       }
//     }
//   }

//   // WHEN IN MOBILE
//   &__desktop {
//     @media (max-width: 991px) {
//       display: none !important;
//     }
//   }

//   // IF SIDE NAV FULL WIDTH WITHOUT PADDING
//   &--full-width {
//     padding-left: 0;
//     padding-right: 0;

//     .side-nav__logo,
//     .side-nav__section h3,
//     .side-nav__list-items > li > a {
//       padding-left: 2rem !important;
//       padding-right: 2rem !important;
//     }

//     .side-nav__list-items > li > a {
//       border-radius: 0 !important;
//     }
//   }
// }

// NAVBAR

.navbar {
  &__mobile {
    justify-content: flex-start !important;

    img.logo-mobile {
      height: 2 * $base-size;
      margin-left: $base-size;
    }

    @media (min-width: 991px) {
      display: none !important;
    }

    ul.navbar-nav {
      margin-top: $base-size;

      li.nav-item {
        a {
          text-decoration: none;
          padding: 0 1.5 * $base-size 1.5 * $base-size;
          display: flex;
          align-items: center;
          height: 2.5 * $base-size;
          padding-top: 0.5 * $base-size;
          padding-bottom: 0.5 * $base-size;
          letter-spacing: 1px;
          transition: all 0.2s ease-in-out;
          color: $brand-primary-0 !important;

          i,
          img {
            margin-right: $base-size;
          }

          &:active,
          &.active {
            border-radius: 5px;
            background-color: rgba($brand-primary-0, 0.05);
            font-weight: bold;
            color: $brand-primary-0 !important;
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
  }
}

// TOP NAV

.top-nav {
  border-bottom: 1px solid $gray-2;
  background-color: $brand-primary-0;
  left: 0;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 999;

  &__tabs {
    background-color: $gray-2;
    display: flex;
    justify-content: space-between;
    width: 100%;

    // 2nd nav underline btn-tab

    .tab-pills {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      margin: 0;

      &__item {
        align-items: center;
        display: flex;
        height: 2.857 * $base-size;

        &:hover,
        &:active,
        &.active {
          border-bottom: 3px solid $brand-primary-0;
          color: $brand-primary-0;
          cursor: pointer;
          font-weight: bold;
        }

        &.active {
          // ACTIVE STATE BTN-TAB

          .btn-tab {
            color: $brand-primary-0;
            font-weight: bold;
          }
        }
      }
    }

    .new-btn {
      align-items: center;
      display: flex;
      float: right;
      justify-content: center;
      margin: 4px 1.25 * $base-size;
      max-height: 2 * $base-size;

      .fa-plus {
        font-size: 0.8 * $base-size;
      }
    }
  }

  &__nav {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .nav-list {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 0;

      &__item {
        margin-right: 1.5 * $base-size;
        color: $white !important;

        // Active state

        &.active {
          color: $brand-primary-0;
          background: $white;
          border-radius: 3px;

          .btn-tab {
            color: $brand-primary-0;
            font-weight: 600 !important;

            &:last-of-type {
              border-left: 1px solid $brand-primary-0;
            }

            &:focus {
              box-shadow: unset;
            }
          }
        }

        &:last-of-type {
          margin-right: 0;
        }

        .btn-tab {
          color: $white;

          &:hover {
            border-bottom: 1px solid transparent;
            background: $white-opacity-20;
          }

          &:focus {
            box-shadow: unset;
          }
        }
      }
    }
  }

  &__left {
    display: flex;

    &__logo {
      border-right: 1px solid $white-opacity-20;
      padding: 0.625 * $base-size 1.25 * $base-size;
      cursor: pointer;
    }

    &__title {
      align-self: center;
      margin-left: 1.25 * $base-size;
      cursor: pointer;

      @media (max-width: 450px) {
        display: none;
      }
    }
  }

  &--middle {
    display: flex;
  }

  &__right {
    align-items: center;
    padding: 0 1.25 * $base-size;

    .notification-icon {
      font-size: 1.5 * $base-size;
      border: 0;
      background-color: transparent;
      color: $black-1;
      cursor: pointer;

      &.read {
        margin-right: 18px;
      }

      &::after {
        display: none;
      }

      .badge {
        position: relative;
        text-align: center;
        font-size: 10px;
        bottom: 15px;
        right: 5px;
      }
    }

    .notification-dropdown {
      min-width: 20rem !important;
      max-height: 70vh;
      overflow-y: auto;
      cursor: pointer;

      @media (max-width: 429px) {
        min-width: 12rem !important;
      }

      &__item {
        &.unread {
          background-color: $gray-2;

          &:hover {
            background-color: rgba($gray-2, 0.6);
          }
        }

        &:active {
          background-color: rgba($gray-2, 0.6);
        }
      }
    }

    .dropdown-menu {
      top: 140% !important;
      min-width: 8.5rem !important;
      cursor: pointer;
    }

    .user-thumbnail-dropdown {
      border-radius: 50px;
      display: flex;
      align-items: center;
      color: $white;
      cursor: pointer;

      &__user-information {
        display: flex;
        flex-direction: row;

        &__profile-name {
          margin: auto 10px;
          font-size: 0.875 * $base-size;
        }

        &__profile-photo {
          height: 2.25 * $base-size;
          width: 2.25 * $base-size;
          min-width: 2.25 * $base-size;
          position: relative;
          overflow: hidden;
          padding: 0;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0;
          background: none;

          &::after {
            display: none;
          }

          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .navbar__role {
    display: flex;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    height: 24px;
    padding: 0 0.5em;
    margin-right: 0.5em;
    cursor: pointer;

    p {
      color: rgba(255, 255, 255, 0.5);
      text-transform: uppercase;
      font-size: 12px;
      margin: 0;
    }
  }
}

// TABS

.nav-tabs {
  background-color: $white;
  display: flex;
  width: 100%;
  border-bottom: 0 !important;

  &__item {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    overflow: auto;
    height: 2.857 * $base-size;
    font-weight: bold;

    &.active {
      background: $brand-primary-0;
      border-radius: 3px;

      .btn {
        color: $white !important;
      }
    }

    &:hover {
      .btn {
        background-color: $brand-primary-opacity-10;
      }
    }

    .btn {
      min-width: 80px !important;
      padding: 0.5rem 1.5rem !important;

      &:focus {
        box-shadow: unset;
      }
    }
  }
}

.back-header {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  margin: 0 0 1rem;
}

.back__section {
  padding: 2rem 2rem 1rem 0;
}

.back-icon {
  color: $gray-6;
  font-size: 1.25 * $base-size;
  margin-right: 0.5 * $base-size;
}

.breadcrumb-triangle {
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
}

.nav-triangle {
  border-left: solid 6px #aaa;
  border-bottom: solid 6px transparent;
  border-top: solid 6px transparent;
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 28px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 0;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px;
}

.mobile-nav-toggle i {
  color: #45505b;
}

.mobile-nav-active {
  overflow: hidden;

  main {
    pointer-events: none;
  }
}

.mobile-nav-active #header {
  left: 0 !important;
}

.mobile-nav-active .mobile-nav-toggle {
  color: #fff;
  background-color: #0563bb;
}