.ml {
  &-6 {
    margin-left: 4rem;
  }

  &-7 {
    margin-left: 5rem;
  }

  &-8 {
    margin-left: 6rem;
  }

  &-9 {
    margin-left: 7rem;
  }

  &-10 {
    margin-left: 8rem;
  }
}

.mr {
  &-6 {
    margin-right: 4rem;
  }

  &-7 {
    margin-right: 5rem;
  }

  &-8 {
    margin-right: 6rem;
  }

  &-9 {
    margin-right: 7rem;
  }

  &-10 {
    margin-right: 8rem;
  }
}

.mt {
  &-6 {
    margin-top: 4rem;
  }

  &-7 {
    margin-top: 5rem;
  }

  &-8 {
    margin-top: 6rem;
  }

  &-9 {
    margin-top: 7rem;
  }

  &-10 {
    margin-top: 8rem;
  }
}

.mb {
  &-6 {
    margin-bottom: 4rem;
  }

  &-7 {
    margin-bottom: 5rem;
  }

  &-8 {
    margin-bottom: 6rem;
  }

  &-9 {
    margin-bottom: 7rem;
  }

  &-10 {
    margin-bottom: 8rem;
  }
}

.mx {
  &-6 {
    margin: 0 4rem;
  }

  &-7 {
    margin: 0 5rem;
  }

  &-8 {
    margin: 0 6rem;
  }

  &-9 {
    margin: 0 7rem;
  }

  &-10 {
    margin: 0 8rem;
  }
}

.my {
  &-6 {
    margin: 4rem 0;
  }

  &-7 {
    margin: 5rem 0;
  }

  &-8 {
    margin: 6rem 0;
  }

  &-9 {
    margin: 7rem 0;
  }

  &-10 {
    margin: 8rem 0;
  }
}