// MAIN PAGE BASE

.main-page {
  display: flex;
  height: 100vh;
  overflow-y: hidden; // hidden to focus on main content scroll
  width: 100vw;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  .main-content {
    height: 100%;
    overflow-y: hidden; // over flow on the body only
    padding: 0.75 * $base-size;

    &__container {
      background-color: $white;
      height: calc(100vh - 3rem);
    }
  }

  &__content {
    height: 100vh;
    width: 100%;
  }
}

// Page layout

.page {
  padding: 1.5rem;
  height: 100%;

  &__header {
    margin-bottom: 1rem;
  }

  &__title {
    color: $brand-primary-0;
  }

  &__footer {
    position: absolute;
    padding: 0.5rem 1.5rem;
    background-color: $brand-primary-opacity-10;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;

    // For page footer min width of btn is 170

    .btn-primary {
      min-width: 12.143 * $base-size;
    }

    &--between {
      justify-content: space-between;
    }

    &--right {
      justify-content: flex-end;
    }

    &--center {
      justify-content: middle;
    }

    &__left,
    &__right {
      display: flex;
      white-space: nowrap;
    }
  }

  // Modifier

  &--plan-settings,
  &--data-import {
    padding: 1.5rem 0 3rem;
    height: unset;

    .page__header {
      padding: 0 1.5rem;
    }

    .page__body {
      padding: 0 1.5rem;
      height: unset;
    }
  }

  &--resources {
    display: flex;
    padding: 0;
    height: unset;

    .page__sidenav {
      width: 550px;

      .pagination {
        position: relative !important;
        bottom: unset;
        left: unset;

        &__left,
        &__right {
          display: flex;
          color: $brand-primary-0;

          .btn-icon {
            padding: 0 !important;
          }
        }

        &__counter {
          white-space: nowrap;
          color: $brand-primary-0;
          font-size: 0.75rem;
          gap: 0.25rem;
          font-weight: bold;
        }
      }
    }

    .page__content {
      padding: 1rem;
      width: 100%;
      position: relative;

      &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        &__middle {
          padding-right: 24rem;
          text-align: center;

          .label-container {
            display: flex;
            justify-content: center;
            gap: 0.5rem;
          }
        }
      }
    }
  }
}

.toast-top-center {
  margin-top: 2.5%;
}
