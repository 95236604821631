.breadcrumb-custom {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  align-items: center;

  .breadcrumb-item {
    &:active {
      color: $brand-primary-0;
    }

    &:disabled {
      color: $gray-4;
    }
  }
}
