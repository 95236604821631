.badge {
  text-align: center;
  border: 50%;
  padding: 0.75em 2em !important;
  font-size: 0.8em !important;

  &--border {
    height: 22px;
    width: 22px;
    border: 1px solid $gray-4;
    border-radius: 3px;
    background: $white;
  }

  &.badge-primary {
    background-color: $brand-primary-0;
    color: $white;
  }

  &.badge-learnerhub {
    background-color: rgba(204, 204, 204, 0.349);
    color: $gray-10;
  }
}

.badge-group {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  max-width: 550px;

  &--end {
    justify-content: flex-end;
  }
}
