/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
	font-family: "Open Sans", sans-serif;
	color: #272829;
  }
  
  a {
	color: #0563bb;
	text-decoration: none;
  }
  
  a:hover {
	color: #067ded;
	text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
	font-family: "Raleway", sans-serif;
  }