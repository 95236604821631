// All IDSC BRAND COLORS

$brand-primary-0: #4daae0; // Prediction
$brand-primary-1: #283b7b; // Monitoring
$brand-primary-2: #172355; // Analysis
$brand-primary-3: #971b26; // Reporting
$brand-primary-4: #e7363c; // Data housing
$brand-primary-5: #be2736; // Data Management
$brand-primary-opacity-10: #f5f6fa; // Primary hover
$brand-secondary: #ebf0ff; // LIGHT BLUE
$brand-learnerhub: #705903bd; // Monitoring
$brand-learnerhub-light: #ecc538bd; // Monitoring

$white: #fff;
$white-opacity-20: hsla(0deg, 0%, 100%, 0.2);

// Gray Section

$gray-0: #f7f7f7; // SECONDARY HEADER TABLE
$gray-1: #f8f9fa; // LEFT PANEL NAV, LABELS
$gray-2: #f2f2f2; // BORDER COLOR
$gray-3: #dee2e6; // SOCIAL MEDIA LOGIN TEXT
$gray-4: #e6e6e6; // ARCHIVE BUTTON INACTIVE
$gray-5: #adb5bd; // ARCHIVE BUTTON ACTIVE
$gray-6: #aaa; // TABS
$gray-7: #495057; // BANNER LABEL
$gray-8: #343a40; // STATUS BAR CONTAINER
$gray-9: #3b3b3b; // PARAGRAPH
$gray-10: #707070; // TOOL TIPS
$gray-11: #7d807e; // GREY BOX IN TABLE

// Black Section

$black-0: #000; // BOX SHADOW
$black-1: #2d3e50; // TEXT COLORS, HEADER
$black-2: #0c343d; // SIGN UP BUTTON

// other Section

$black: #000;
$blue: #4ba1eb !default; // INFO
$navy-blue: #283b7b;
$purple: #6f42c1 !default;
$violet: #a74beb !default;
$red: #fc596f; // ERROR, DANGER
$pink: #e83e8c !default;
$orange: #ebad4b !default; // WARNING
$red-orange: #db6c12 !default; // WARNING
$yellow: #ebdf4b !default; // PENDING
$green: #28a745 !default; // SUCCESS
$teal: #20c997 !default;
$cyan: #68e2e2 !default;
$indigo-light: #324892 !default;
$indigo-dark: #1f2d5e !default;
$light: #fcfaec !default;

// Opacity
$pink-opacity-10: rgba($pink, 0.1);
$green-opacity-10: rgba($green, 0.1);
$red-opacity-1: rgba($red, 0.1);
$red-opacity-10: rgba($red, 0.5);

// Base sizel

$base-size: 14px;

// FONT SIZES & OTHER MEASUREMENTS

$font-family: "Roboto";
$font-family-fontawesome: "Font Awesome 5 Free";
$h1-font: normal normal 700 24px / 1.3 $font-family;
$h2-font: normal normal 700 18px / 1.3 $font-family;
$h3-font: normal normal 700 16px / 1.3 $font-family;
$h4-font: normal normal 700 14px / 1.3 $font-family;
$h5-font: normal normal 700 12px / 1.3 $font-family;
$h6-font: normal normal 600 12px / 1.3 $font-family;
$p-font-size: normal normal 400 14px / 1.3 $font-family;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
