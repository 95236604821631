// SCROLLBAR

// SCROLLBAR layout

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: $gray-1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: rgba($gray-5, 0.35);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: rgba($gray-6, 0.5);
}

// HUBSPOT MODAL

.hubspot-connection {
  .modal-content {
    border-radius: 20px;
  }
}
