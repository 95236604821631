.empty-state {
  color: $gray-8;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: $base-size;
  text-align: center;

  .empty-state__icon {
    font-size: 1.5 * $base-size;
  }

  .empty-state__text,
  .empty-state__link {
    font-size: 0.875 * $base-size;
    font-weight: 600;
    line-height: 1.5 * $base-size;
  }

  .empty-state__link {
    color: $brand-primary-0;
  }
}
