.font-weight-boldest {
  font-weight: 1000;
}

.nopadding {
  margin: 0 !important;
  padding: 0 !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-xl {
  -webkit-text-stroke-width: thin;
  font-size: 3.1875rem;
  font-weight: 1000;
}

.text-divider {
  border-left: 1px solid $gray-4;
  height: 12.25px;
  margin: 0 0.5rem 1rem;
}

.center-xy {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.breadcrumb-text--active {
  color: $gray-6;
  font-size: $base-size !important;
  font-weight: 600 !important;
}
