@import "../base/variables";

button,
a {
  cursor: pointer;
  outline: none !important;
  user-select: none !important;
}

a, button {
  &.disabled,
  &:disabled {
    opacity: 0.5;
  }
}

.btn-group {
  height: 2.5 * $base-size;
}

.btn-toolbar {
  display: block !important;
  white-space: nowrap;

  button {
    height: 2.5 * $base-size !important;
    margin-right: 0.25 * $base-size;
  }

  &:last-child {
    margin-right: 0 !important;
  }
}

// LINK

a {
  font-size: 0.875 * $base-size;
  text-decoration: underline;

  &.btn {
    background: $brand-primary-0;
    color: $white;
    letter-spacing: 1px;
    display: inline-block;
    font-weight: 700;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
    transition: transform .3s;

    &.btn-resume {
      padding: 1.5rem 2rem;
      font-size: 2rem;
      width: 300px;
      border-radius: 2rem;

      &:hover {
        border-color: #deeaeb;
        color: $white;
        box-shadow: 0px 0 15px 0 rgba(18, 138, 218, 0.699);
      }
    }

    &.btn-profile {
      padding: 1rem 2rem;
      font-size: 2rem;
      width: 250px;
      border-radius: 2rem;

      &:hover {
        border-color: #deeaeb;
        color: $white;
        box-shadow: 0px 0 15px 0 rgba(18, 138, 218, 0.699);
      }
    }
  }
}

.btn {
  border-radius: 5px;
  font-size: 1 * $base-size !important;
  font-weight: normal !important;

  &--icon-text {
    display: flex;
    justify-content: flex-start;
    min-width: none;

    &__icon {
      font-size: 0.85 * $base-size;
      margin-right: 0.5 * $base-size;
    }

    &__label {
      font-size: 0.875 * $base-size;
    }
  }

  // COLORS

  &.btn-primary {
    align-items: center;
    background-color: $brand-primary-0;
    border: 1px solid $brand-primary-0 !important;
    color: $white !important;
    width: 100%;

    // Size

    &--w-10 {
      width: 10%;
    }

    &--w-25 {
      width: 25%;
    }

    &--w-50 {
      width: 50%;
    }

    &--w-75 {
      width: 75%;
    }

    // Icon

    &--w-100 {
      width: 100%;
    }

    // Icon

    i {
      color: $white;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $brand-primary-0 !important;
      border: 1px solid $brand-primary-0 !important;
      color: $white !important;
      opacity: 0.9;
    }

    &.disabled,
    &:disabled {
      background-color: $brand-primary-0;
      border: 1px solid $brand-primary-0 !important;
      color: $white !important;
      opacity: 0.5;
    }
  }

  &.btn-light {
    align-items: center;
    background-color: $white !important;
    border: 1px solid $gray-2 !important;
    color: $black-2 !important;
    display: flex;
  }

  &.btn-danger {
    background-color: $red;
  }

  &.btn-outline {
    background-color: transparent;
    border: 1px solid $brand-primary-0 !important;
    color: $brand-primary-0 !important;

    i {
      color: $brand-primary-0;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $brand-secondary !important;
      border-color: $brand-primary-0 !important;
    }
  }

  &.btn-white {
    background-color: transparent;
    color: $brand-primary-0 !important;
    display: flex;
    align-items: center;

    i {
      color: $brand-primary-0;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $white !important;
    }
  }

  &.btn-tab__content {
    &:active,
    &:hover:active,
    &.active {
      background-color: $brand-primary-0 !important;
      border: 1px solid $brand-primary-0 !important;
      color: $white !important;
      cursor: pointer;
    }
  }

  // datepicker

  &.calendar {
    &:active {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=") !important;
      background-position: 50%;
      width: 2.75 * $base-size;
      background-repeat: no-repeat;
      background-size: 23px;
    }

    &.ngb-dp-arrow-btn {
      min-width: auto !important;
    }
  }

  &.ngb-dp-arrow-btn {
    min-width: auto !important;
  }
}

.set-group {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 1rem 0.5rem;
  float: right;
  border-left: 1px solid $gray-4;
  margin-top: 0.15rem;

  .btn {
    padding: 0.25rem;

    .indicator {
      height: 12.5px;
      width: 6px;
      background-color: $white;
      border-radius: 1rem;
      border: 1px solid $gray-5;
      display: block;
      position: relative;
      margin-left: 0 !important;
    }

    &--active {
      .indicator {
        background-color: $brand-primary-0;
        border: 0;
      }
    }
  }
}

.btn-icon {
  background-color: $brand-primary-0;
  border-radius: 3px;
  border-style: none;
  color: $white;
  cursor: pointer;
  font-size: 0.75 * $base-size;
  padding: 0.3rem 0.45rem 0.3rem 0.5rem;

  &--square {
    align-items: center;
    display: flex;
    height: 1.25 * $base-size;
    justify-content: center;
    width: 1.25 * $base-size;
  }

  &--blue {
    color: $gray-4 !important;
    background-color: transparent !important;

    &:hover,
    &:focus,
    &:active {
      color: $brand-primary-0 !important;
    }
  }
}

.action-group {
  display: flex;
  align-items: center;

  &--status {
    .btn-icon {
      padding: 0;

      i-feather {
        height: 18px;
        width: 18px;

        &[name="x-circle"] {
          color: $red;
        }

        &[name="check-circle"] {
          color: $green;
        }
      }

      &:hover {
        i-feather {
          height: 19px;
          width: 19px;
          transition: 0.5s ease-in-out;
        }
      }
    }
  }

  .btn {
    &-icon {
      padding: 0.25rem;
      color: $gray-6;

      &:hover {
        color: $brand-primary-0;
      }

      &:active {
        color: $brand-primary-0;
      }

      &:disabled {
        color: $gray-4;
      }

      &:focus {
        box-shadow: unset;
      }
    }
  }
}

.btn-icon-group {
  display: flex;
  align-items: center;
  height: 1.5 * $base-size;
  gap: 2px !important;
  justify-content: flex-end;
  margin: 12px;

  span {
    cursor: pointer;
    margin-right: 0.5 * $base-size;
    color: $gray-6;
  }
}

.btn-tab {
  border: 0;
  color: $gray-6;
  font-size: 0.875 * $base-size;
  padding: 0.643 * $base-size $base-size;
}

// Bootstrap's Button Outline Override

.btn-outline-secondary {
  border-color: $gray-4 !important;
  color: $brand-primary-0 !important;
  font-size: 0.875 * $base-size !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $brand-primary-0 !important;
    border-color: $gray-6 !important;
    box-shadow: none !important;
    color: $white !important;
    outline: 0 none !important;
  }
}

.btn-search {
  background-color: transparent !important;

  &__icon {
    color: $gray-6;
    font-size: $base-size !important;
  }
}

.icon-button {
  cursor: pointer;
}

.btn-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.btn-modal__close {
  cursor: pointer;
  font-size: 25px;
}