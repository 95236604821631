.pagination {
  width: 97%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0.44rem;

  ngb-pagination {
    position: relative;
  }

  &__counter {
    white-space: nowrap;
    color: $brand-primary-0;
    font-size: 0.75rem;
    gap: 0.25rem;
    font-weight: bold;
    padding: 0 4px;
  }

  &--resources {
    ngb-pagination {
      .page-link {
        padding: 0.5px !important;
        color: $black !important;
      }
    }
  }
}

.page-link {
  border-color: transparent !important;
  color: $brand-primary-0 !important;

  &:hover {
    background-color: $gray-1 !important;
    border-radius: 4px !important;
  }
}

.page-item.active .page-link {
  background-color: $brand-primary-0 !important;
  border-color: $brand-primary-0 !important;
  border-radius: 4px;
  color: $white !important;
}
