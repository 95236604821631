// NGBDROPDOWN

.ngb-dropdown.dropdown {
  display: inline-block;
  width: 100% !important;

  button.btn-outline,
  button.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-color: $gray-5 !important;
    text-align: left;
    padding-left: 0.9375rem !important;
    padding-right: 0.9375rem !important;
    height: 2.5rem !important;
    font-size: 12px !important;
    width: 100% !important;
    color: $gray-6;

    &.is-invalid {
      border-color: $red !important;
    }

    &::placeholder {
      font-size: 0.875rem !important;
      color: $gray-6 !important;
    }

    &::after {
      content: initial;
    }

    &:active {
      background-color: $white;
      border-color: $gray-5;
      color: $brand-primary-0;
    }

    &:disabled {
      background-color: $gray-2;
    }

    &.selected {
      color: $black;
    }
  }

  i.fa {
    color: $brand-primary-0;
    position: absolute;
    right: 0;
    padding: 0 15px 0 10px;
    margin-right: 1px;
    background-color: $white;

    &:hover {
      background-color: $gray-2;

      i.fa {
        background-color: $gray-2;
      }
    }
  }
}

.dropdown-menu.show {
  border: 1px solid $gray-2;
  box-shadow: 0 10px 15px rgba($black-1, 0.1);
  background-color: $white !important;
  color: $brand-primary-0 !important;
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  display: block;
  text-overflow: ellipsis;

  .dropdown-item {
    font-size: 0.875 * $base-size;
    display: flex;
    align-items: center;
    word-break: break-word;
    white-space: normal;
  }
}
