.side-nav {
  width: 100%;
  height: calc(100vh - 3.1rem);
  border-right: 1px solid $gray-4;
  padding-left: 0.5rem;

  i-feather {
    height: 18px !important;
    width: 18px !important;
  }

  &__header {
    padding: 0.5rem 0.75rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray-4;

    &__left {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    &__right {
      display: flex;
    }

    &__middle {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__body {
    overflow-y: auto;
    overflow-x: unset;
    height: calc(95vh - 3rem);
  }

  &__list {
    height: 100%;
  }

  .center-xy {
    position: relative;
    margin-top: 25px;
  }
}

.programme {
  border-bottom: 1px solid $gray-4;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border-left: 4px solid $white;

  &:hover,
  &.active {
    border-left: 4px solid $brand-primary-1;
  }

  &.active {

    label,
    span {
      font-weight: normal !important;
    }
  }

  &__code {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 0.75rem;
  }

  &__title {
    &:hover {
      cursor: pointer;
    }

    h4 {
      font-weight: normal !important;
      margin-bottom: 0;

      &.active {
        h4 {
          font-weight: normal !important;
        }
      }
    }

    .label {
      font-size: 0.65rem !important;
    }
  }

  &__status {
    .action-group {
      flex-direction: column;

      .btn-icon {
        padding: 0;

        i-feather[name="check-circle"] {
          color: $green;
        }

        i-feather[name="x-circle"],
        i-feather[name="slash"] {
          color: $red;
        }

        i-feather[name="clock"] {
          color: $yellow;
        }

        i.bxs-circle-three-quarter {
          color: $blue;
          border: 1px solid $blue;
          border-radius: 10px;
        }
      }
    }
  }
}
