.page {
  &--organisation-structure,
  &--offer-types,
  &--programmes {
    .page__header {
      display: flex;
      justify-content: space-between;

      &__left {
        label {
          margin-bottom: 0;
        }

        .page__title {
          margin-bottom: 0;
        }
      }

      &__right {
        display: flex;
      }
    }
  }
}
