.upload-form {
  height: 100%;

  &__list-container {
    overflow: hidden;
    height: 90%;

    &__list {
      width: 100%;
      height: 75%;
    }
  }
}
