/* ******************* Switch */

label.setting {
  cursor: pointer;
  display: grid;
  grid-template-areas: "col-1 col-2 col-3";
  grid-gap: 10px;
  align-items: center;
}

label.setting .on {
  color: rgba(0, 0, 0, 0.3);
  grid-area: col-3;
}

label.setting .icon,
input.switch {
  cursor: pointer;
  grid-area: col-2;
  justify-self: center;
}

label.setting .off {
  color: rgba(0, 0, 0, 0.6);
  grid-area: col-1;
  justify-self: flex-end;
}

label.setting .icon {
  position: relative;
  width: 40px;
  height: 24px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.07);
  box-shadow: inset 0 0 4 -2 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
}

input.switch:checked + .icon {
  background: #3057d5;
}

input.switch:checked ~ .on {
  color: rgba(0, 0, 0, 0.6);
}

input.switch:checked ~ .off {
  color: rgba(0, 0, 0, 0.3);
}

input.switch {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  display: none;
}

label.setting .icon span {
  position: absolute;
  content: "";
  width: 25.72px;
  height: 25.72px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
  background-color: $white;
  transform: scale(0.7);
  left: 0;
  top: calc(50% - 13px);
  transition: all 0.2s ease-in-out;
}

label.setting .icon span::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 8px;
  top: calc(50% - 4px);
  left: calc(50% - 1px);
  background: $brand-primary-5;
}

input.switch:checked {
  background: $brand-primary-0;
}

input.switch:checked + .icon span {
  left: calc(100% - 25.732px);
}

input.switch:checked + .icon span::before {
  background: $brand-primary-0;
}

.switch {
  width: 50px;
  height: 17px;
  position: relative;
  display: inline-block;
}

.switch input {
  display: none;
}

.slider {
  .disabled::before {
    background-color: $gray-4 !important;
  }
}

.switch .slider {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  background-color: #e7ecf1;
  border-radius: 30px !important;
  border: 0;
  padding: 0;
  display: block;
  margin: 12px 10px;
  min-height: 11px;
}

.switch .slider::before {
  position: absolute;
  background-color: $white;
  height: 15px;
  width: 15px;
  content: "";
  left: 0;
  bottom: -2px;
  border-radius: 50%;
  transition: ease-in-out 0.2s;
  border: 1px solid $gray-5;
}


.switch .slider::after {
  content: "";
  color: $white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 70%;
  transition: all 0.2s;
  font-size: 10px;
}

.switch input:checked + .slider::after {
  transition: all 0.2s;
  left: 30%;
  content: "";
}

.switch input:checked + .slider {
  background-color: #d3d6d9;
}

.switch input:checked + .slider::before {
  transform: translateX(15px);
  background-color: $navy-blue;
}
