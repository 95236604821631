.outer-page {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
}

.outer-page-content {
  width: 100%;
}
