/* You can add global styles to this file, and also import other style files */

@import './assets/sass/styles';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import '~ng-wizard/themes/ng_wizard.min.css';

// regular style toast
@import '~ngx-toastr/toastr';

body {
  background-color: #fff;
}

