body.dark, .dark #preloader {
  background-color: #3b3b3b !important;
}

.dark {
  #preloader {
    background-color: #3b3b3b !important;

    &:before {
      border-top-color: #636363 !important;
      border-bottom-color: #636363 !important;
    }
  }
  footer {
    background-color: #3a3a3a !important;
  }

  i {
    background: none !important;
  }

  p,
  h1,
  h2,
  h3:not(.resume-item > h3),
  h4,
  h5,
  span:not(.typing, .modal-title),
  li {
    color: $white !important;
  }

  #profile:before {
    background: rgba(32, 32, 32, 0.7) !important;
  }

  .resume-item h5 {
    background: #4d4d4d !important;
  }

  .contact .icon-box {
    background: #4b4b4b !important;

    .bg-icon {
      background: #747474 !important;
    }
  }

  .mobile-nav-toggle {
    color: $white !important;
  }

  @media (max-width: 991px) {
    #header {
      background: #3b3b3b !important;
      border: 1px solid  #2e2e2e  !important
    }
  }

  .copyright {
    color: rgb(151, 151, 151) !important;
  }

  .nav-menu a:not(.active) {
    background-color: #747474 !important;
    color: $white !important;
  }

  .modal-content {
    background-color: #575757 !important;
    color: $white !important;
  }
}