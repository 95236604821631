// Default styling

.modal {
  // Modals default elements

  .modal-header {
    padding: 0 0 1rem !important;
    border-bottom: 1px solid $gray-4 !important;
    align-items: center;

    .modal-title {
      color: $brand-primary-0;
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .modal-body {
    .section {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 640px;
      height: 100%;

      &:nth-last-of-type(3) {
        border-right: 0;
      }
    }
  }

  .modal-footer {
    border-top: 1px solid $gray-4 !important;

    &--sm {
      border-top: 0 none !important;
    }

    &--center {
      display: flex;
      align-items: center;
      justify-content: center;

      .action-group {
        float: unset;

        .btn {
          width: 10rem;
        }
      }
    }

    &--right {
      .action-group {
        float: right;
        display: flex;

        .btn {
          width: 9rem;
        }
      }
    }
  }

  // Elements like radio button, checkbox, input/textboxes

  .form-radio {
    &__label {
      font-weight: normal !important;
    }
  }

  .form-check {
    &__label {
      font-weight: normal !important;
    }
  }

  .form-group {
    label {
      font-weight: normal !important;
    }

    .form-control {
      height: 28px !important;
    }
  }

  .ngb-dropdown.dropdown {
    display: inline-block;
    width: 100% !important;

    button.btn-outline,
    button.dropdown-toggle {
      height: 30px !important;

      &.selected {
        color: $black;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .ngb-dropdown {
    &__menu {
      height: 420px;
      overflow-y: auto;
    }
  }

  // Modals on programmes page

  &--filter-old,
  &--filter-new,
  &--data-filter,
  &--org-struct-old,
  &--org-struct-new,
  &--import {
    .modal-content {
      box-shadow: 0 4px 15px $brand-primary-0;
    }

    .modal-header {
      padding: 0 0 0.5rem !important;
    }

    .modal-body {
      padding: 1rem 0 0 !important;
      overflow: hidden;
    }

    .modal-footer {
      padding: 0.5rem 0 0;
    }
  }

  // Org Structure page filter

  &--org-struct-new {
    .modal-dialog {
      position: absolute;
      top: 120px;
      right: 210px;
      min-height: unset;
      margin: 0;
    }
  }

  &--org-struct-old {
    .modal-dialog {
      position: absolute;
      top: 120px;
      right: 250px;
      min-height: unset;
      margin: 0;
    }
  }

  // Modals on resource page

  &--filter-old {
    .modal-dialog {
      position: absolute;
      top: 120px;
      right: 210px;
      min-height: unset;
      margin: 0;
    }
  }

  &--filter-new {
    .modal-dialog {
      position: absolute;
      top: 120px;
      right: 180px;
      min-height: unset;
      margin: 0;
    }
  }

  &--import {
    .modal-dialog {
      position: absolute;
      top: 120px;
      right: 90px;
      min-height: unset;
      margin: 0;
    }

    .modal-body {
      padding: 0 !important;

      .section {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 350px;

        &:first-of-type {
          padding-top: 1rem;
        }
      }
    }
  }

  &--display-settings {
    .modal-dialog {
      max-width: 1200px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action-group {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-right: 1rem;
      }

      .btn-group {
        label {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }

        input {
          height: 35px;
        }
      }
    }

    .modal-body {
      overflow: unset;
      padding: 0;
    }

    .form {
      height: 100%;

      // Form section will have a scroll use for body content

      &__section {
        overflow-y: auto;
        overflow-x: hidden;
        height: 45rem;
        padding: 1rem;
        margin-bottom: 0;
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0 0;

        // position
        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--update-selected-courses {
    .modal-dialog {
      max-width: 400px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action-group {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-right: 1rem;
      }

      .btn-group {
        label {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }

        input {
          height: 35px;
        }
      }
    }

    .modal-body {
      overflow: unset;
      padding: 0;
    }

    .form {
      // Form section will have a scroll use for body content

      &__section {
        overflow-y: auto;
        overflow-x: hidden;
        height: 45rem;
        padding: 1rem;
        margin-bottom: 0;
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0;

        // position

        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--display-filters {
    .modal-dialog {
      max-width: 600px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action-group {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-right: 1rem;
      }

      .btn-group {
        label {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }

        input {
          height: 35px;
        }
      }
    }

    .modal-body {
      overflow: unset;
      padding: 0;
    }

    .form {
      // Form section will have a scroll use for body content

      &__section {
        overflow-y: auto;
        overflow-x: hidden;
        height: 45rem;
        padding: 1rem;
        margin-bottom: 0;
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0 0;

        // position

        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--data-filters {
    .modal-dialog {
      max-width: 800px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action-group {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-right: 1rem;
      }

      .btn-group {
        label {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }

        input {
          height: 35px;
        }
      }
    }

    .modal-body {
      overflow: unset;
      padding: 0;
    }

    .form {
      // Form section will have a scroll use for body content

      &__section {
        overflow-y: auto;
        overflow-x: hidden;
        height: 45rem;
        padding: 1rem;
        margin-bottom: 0;
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0 0;

        // position

        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--qual-type {
    text-align: center;

    .modal-dialog {
      max-width: 1500px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action-group {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-right: 1rem;
      }

      .btn-group {
        label {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }

        input {
          height: 35px;
        }
      }
    }

    .modal-body {
      overflow: unset;
      padding: 0;
    }

    .form {
      height: 100%;

      // Form section will have a scroll use for body content

      &__section {
        overflow-y: auto;
        overflow-x: hidden;
        height: 38rem;
        padding: 1rem;
        margin-bottom: 0;
        text-align: left;

        &--sm {
          height: 5rem !important;
          overflow-y: unset;
          overflow-x: hidden;
        }

        // sm

        &--md {
          height: 10rem !important;
        }

        // md

        &--custom {
          height: 15rem !important;
        }

        // md-2

        &--lg {
          height: 30rem !important;
        }
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0 0;

        // position

        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--organisation-structure {
    .modal-dialog {
      max-width: 1200px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action-group {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-right: 1rem;
      }

      .btn-group {
        label {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }

        input {
          height: 35px;
        }
      }
    }

    .modal-body {
      overflow: unset;
      padding: 0;
    }

    .form {
      height: 100%;

      // Form section will have a scroll use for body content

      &__section {
        overflow-y: auto;
        overflow-x: hidden;
        height: 38rem;
        padding: 1rem;
        margin-bottom: 0;
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0 0;

        // position
        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--offer-types {
    .modal-dialog {
      max-width: 600px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action-group {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-right: 1rem;
      }

      .btn-group {
        label {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }

        input {
          height: 35px;
        }
      }
    }

    .modal-body {
      overflow: unset;
      padding: 0;
    }

    .form {
      // Form section will have a scroll use for body content

      &__section {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 1rem;
        margin-bottom: 0;
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0 0;

        // position

        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--programme-data-filters {
    .modal-dialog {
      max-width: 1200px;
    }

    .modal-body {
      overflow: hidden;
      padding: 0;
    }

    .form {
      height: 100%;

      // Form section will have a scroll use for body content

      &__section {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 1rem;
        height: 100%;
        margin-bottom: 0;
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0 0;

        // position

        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }

    .section {
      max-height: 540px !important;
    }
  }

  &--update-selected-entries {
    .modal-dialog {
      max-width: 1200px;
    }

    .modal-body {
      overflow: hidden;
      padding: 0;
    }

    .form {
      height: 100%;

      // Form section will have a scroll use for body content

      &__section {
        overflow-y: auto;
        overflow-x: hidden;
        height: 38rem;
        padding: 1rem;
        margin-bottom: 0;
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0 0;

        // position

        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--update-programme-qual-selected-entries {
    .modal-dialog {
      max-width: 400px;
    }

    .modal-body {
      overflow: hidden;
      padding: 0;
    }

    .form {
      height: 100%;

      // Form section will have a scroll use for body content

      &__section {
        overflow-y: auto;
        overflow-x: hidden;
        height: 15rem;
        padding: 1rem;
        margin-bottom: 0;
      }

      // form__footer will stick below the form__section

      &__footer {
        display: flex;
        border-top: 1px solid $gray-4;
        padding: 0.5rem 0 0;

        // position

        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--program-form {
    .modal-footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0;
    }
  }

  &--program-form,
  &--organisation-structure {
    .modal-dialog {
      max-width: 1400px !important;
    }

    .modal-body {
      overflow: unset;
    }

    .section {
      max-height: 450px !important;
    }

    .table-container__content {
      height: 450px !important;
    }
  }

  &--navigation {
    .card {
      padding: 0;
    }

    .card-header {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-bottom-left-radius: 0;
      background: $brand-primary-0;
      color: $white;
      text-align: center;
      font-weight: bold;
    }

    .card-body {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .nav-card {
      width: 100%;

      &__item {
        font-weight: bold;
        padding: 0.5rem;
        text-align: center;

        a {
          &:hover {
            color: $brand-primary-opacity-10;
          }
        }
      }
    }
  }

  &--parameters {
    height: 27.714rem !important;
    display: block !important;
    box-shadow: -1px 5px 8px 2px rgba(0, 0, 0, 0.2);
    background-color: $white;
    position: absolute !important;
    border-radius: 12px;
    top: 0 !important;
    left: 0;
    width: 100%;
    z-index: 1;

    // make form-controls smaller inside model parameters

    .form-control {
      height: 1.55rem !important;

      label {
        font-size: 0.75rem !important;
        font-weight: normal !important;
        margin-bottom: 0.25rem !important;
      }

      &--textbox {
        display: flex;
        justify-content: center;
        margin-left: 12px;
      }

      &--changes {
        display: flex;
        justify-content: center;
        margin-right: 26px;
      }
    }

    // make form-radio smaller inside model parameters

    .form-radio {
      margin: 0.5rem 0.5rem 0.5rem 0 !important;
      height: 14px !important;

      .form-radio__label {
        font-weight: normal !important;
      }
    }

    // Modal elements inside model parameters

    .modal__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 1rem;
      gap: 1.5rem;
      border-bottom: 1px solid $gray-4;
      border-top-left-radius: calc(0.3rem - 1px);
      border-top-right-radius: calc(0.3rem - 1px);
    }

    .modal__title {
      margin-bottom: 0;
    }

    .modal__body {
      padding: 1rem;
      height: 100%;
      max-height: 21rem;

      // Changed row grid sizes margin and padding

      .row {
        margin-left: -8px !important;
        margin-right: -8px !important;
      }

      .row > [class*="col-"] {
        padding-left: 8px !important;
        padding-right: 8px !important;
      }

      // Decreased the size eof col-lg-1 when inside model parameter
      // .col-lg-1 {
      //   flex: 10% !important;
      //   max-width: 10% !important;
      // }
      // Decreased the size eof col-lg-2 when inside model parameter
      // .col-lg-2 {
      //   flex: 14.666667% !important;
      //   max-width: 14.666667% !important;
      // }

      // agr grid container

      .agr-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0.25rem 0.5rem;

        // agr item

        .agr {
          &__item {
            label {
              font-weight: normal !important;
            }
          }
        }
      }
    }

    .modal__footer {
      border-top: 1px solid $gray-4;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      &--right {
        display: flex;
        justify-content: flex-end;
      }

      .action-group {
        display: flex;

        .btn {
          min-width: 6rem;
        }
      }
    }
  }

  .success-modal {
    box-shadow: 0 4px 15px rgba(45, 62, 80, 0.15);
    max-width: 100% !important;
    max-height: 100% !important;

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .modal-icon {
        i-feather {
          color: $green;
          height: 100px !important;
          width: 100px !important;
          padding: 0.5rem;
          margin-top: 1rem;
        }
      }

      .modal-title {
        margin-top: 1rem;
        font: normal normal bold 18px/1.3 $font-family !important;
        color: $brand-primary-0;
      }

      .modal-sub-title {
        color: $black;
        margin-bottom: 1rem;
      }
    }
  }
}

.project-display-modal__content {
  .modal-dialog {
    max-width: 1000px;
    margin: 1.75rem auto;
  }

  @media (max-width: 780px) {
    .modal-dialog {
      max-width: calc(100% - 50px) !important;
    }
  }

  @media (max-width: 515px) {
    .btn-pagination {
      width: calc(100% - 1px) !important;
      font-size: calc(100% - 0.10%) !important;
    }

    .pagination__counter {
      font-size: calc(100% - 0.10%) !important;
    }

    .custom-pagination {
      font-size: calc(100% - 0.10%) !important;
    }
  }
}