footer {
  margin: auto;
  width: 990px;

  .footer-border {
    width: 100%;
    height: 0;
    margin-bottom: 2em;
    border-top: 1px solid #e6e6e6;
  }

  .footer__full-footer {
    clear: both;
    padding: 0 0 120px;
  }
}
