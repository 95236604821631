.dropdown-menu {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;

  .dropdown-item {
    transition: 0.05s background ease-in;
    color: $black;
    font-size: 14px !important;

    &:hover {
      background: $gray-4;
    }
  }
}

.ui-chkbox {
  @include flex-centered;

  width: 25px !important;
  height: 25px !important;

  .ui-chkbox-box.ui-state-active {
    border-color: $gray-8 !important;
    background-color: $indigo-dark !important;
  }

  .ui-chkbox-box {
    @include flex-centered;

    width: 25px !important;
    height: 25px !important;

    &.ui-state-focus {
      box-shadow: 0 0 0 0.2em $gray-5 !important;
      border-color: $gray-5 !important;
    }
  }
}

.pagination {
  margin: 0;
  justify-content: middle;
}

// .form-group {
//   & label {
//     font-size: 0.8rem !important;
//     font-weight: 400 !important;
//     // text-transform: uppercase;
//   }
// }

.page-item {
  .page-link {
    border-radius: 0;
    border: 0;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;

    &:active {
      background-color: $indigo-dark;
      color: $white;
    }

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  &.previous {
    margin: 0 auto 0 0;
  }

  &.next {
    margin: 0 0 0 auto;
  }
}

.toast-container .ngx-toastr {
  padding-left: 60px !important;
}

.toast-container {
  padding-top: 10px;
}

/* bootstrap modal */

.modal-header {
  color: $black;
  font-size: 0.85rem;
  border-bottom: 0 !important;
  padding-bottom: 0.5rem !important;
}

.modal-content {
  border: none !important;
  padding: 0.85rem 1rem;
}

.modal-body {
  overflow-y: auto;
}

.modal-footer {
  border-top: 0 !important;

  &__right {
    display: flex;
    justify-content: flex-end;

    .btn {
      display: inline;
      width: 140px;
    }
  }
}

/* bootstrap button */

.btn-sm {
  font-size: 0.8rem !important;
}

/* table */

.table__body {
  vertical-align: middle;
}

/* PrimeNG table */


.custom-ng-select {
  width: 100% !important;

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background: $indigo-dark;
    color: $white;
  }

  .ng-option.ng-option-marked:hover {
    background: $blue;
    color: $white;
  }

  .ng-select-container {
    border-radius: 0.3em !important;
    border: 2px solid $gray-4 !important;

    .ng-value-container .ng-value {
      background-color: $indigo-dark !important;
      color: $white !important;
    }
  }

  &.ng-select-focused .ng-select-container {
    border: 2px solid $indigo-dark !important;
    box-shadow: none !important;
  }
}

.ngb-dp-week-number,
.ngb-dp-weekday {
  font-style: normal !important;
  color: $black !important;
}

.custom-click-hover {

  .fa,
  .feather {
    color: $gray-6 !important;
    font-size: 1rem;
    cursor: pointer;

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: unset;
    }
  }
}

.custom-button-color {
  background-color: $indigo-dark !important;
  border-color: $indigo-dark !important;
}

.modal-opened {
  overflow-y: hidden;

  .navbar {
    filter: brightness(0.5);
    pointer-events: none;
  }

  .mobile-nav-toggle {
    pointer-events: none;
    z-index: 0;
  }
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

app-custom-pagination {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
