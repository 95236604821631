.run-history-page {
  padding: 2rem;

  .run-history-content {
    width: 100%;
    margin-top: 1.5rem;
    border-bottom: 0 !important;

    &.last-child {
      border-bottom: 0.5px solid $gray-5 !important;
    }
  }
}
