.page {
  &--staff {
    .page__header {
      display: flex;
      justify-content: space-between;

      &__left {
        label {
          margin-bottom: 0;
        }

        .page__title {
          margin-bottom: 0;
        }
      }

      &__right {
        display: flex;

        .action-group {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
}
