.models-page {
  height: 90%;
  width: 100%;
  padding: 2rem;
  overflow: auto;

  .empty-state {
    min-height: 17.5 * $base-size;
  }
}

.fa:not(.fa-play) {
  color: $brand-primary-0;
}

.model-form {
  @media (max-width: 600px) {
    padding-bottom: 4rem;
  }
}
