.accounts-popover {
  width: 100%;
  font-family: 'roboto';

  .popover-body {
    padding: unset;
  }

  .label-section {
    font-weight: bold;
    background-color: $gray-5 !important;
    text-align: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      background-color: $gray-3;
    }
    td {
      padding: 0.5rem 0.75rem;
   
    }
  }
}