@import "../base/variables";

.sign-up-page {
  background-color: $brand-primary-0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  min-height: 569px;

  &__form-container {
    border-radius: 14px;
    background-color: $white;
    padding: 0 3 * $base-size;
    width: 80%;
    max-width: 1000px;
    height: 80%;
    min-height: 400px;
    max-height: 1000px;

    form {
      margin-top: -3 * $base-size;

      .error-note {
        font-size: small;
        color: $red;
        margin-bottom: 7px;
      }
    }

    &__logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30%;
      margin: 2.25 * $base-size 0 0 0;
      padding: 0;

      img {
        justify-self: center;
        align-self: center;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .sign-up-page {
    &__form-container {
      width: 100vw;
      height: 100vh;
      padding: 0 2 * $base-size;
    }
  }
}
