.table-responsive {
  background-color: $white;
  width: auto !important;
  height: calc(100vh - 17.8rem);

  @media (max-width: 1826px) {
    height: calc(100vh - 17.5rem) !important;
  }

  @media (max-width: 600px) {
    height: calc(100vh - 17.5rem) !important;
  }
}

.table-container {
  position: relative;

  // Modifier table

  &--base-table {
    position: relative;

    .table-container__content {
      height: 230px;
      border: 1px solid $gray-4;
      border-bottom: 1px solid $gray-4;
      overflow-y: auto;
      overflow-x: unset;
      position: relative;

      table {
        border-collapse: collapse;
      }

      thead {
        position: sticky;
        top: 0;
      }

      &--md {
        height: 370px !important;
      }
    }

    tfoot {
      position: sticky;
      bottom: -1px;
      padding: 0;
      background: $gray-1;
      width: 100%;

      tr {
        vertical-align: top;

        // Table data style

        td {
          border-bottom: 0;
          border-right: 1px solid $gray-4;
          vertical-align: middle;
          padding: 0.5rem 0.75rem;

          &:last-of-type {
            border-right: 1px solid $gray-4;
          }
        }
      }
    }
  }

  // Modifier table

  &--user-table {
    .table-container__content {
      height: 320px;

      // border: 1px solid $gray-4;

      border-bottom: 1px solid $gray-4;
      overflow-y: auto;

      table {
        border-collapse: collapse;
      }

      thead {
        position: sticky;
        z-index: 1;
        top: 0;
      }
    }

    .table-container__footer {
      display: flex;
      justify-content: space-between;
      border: 1px solid $gray-4;
      padding: 0.5rem;

      &--bottom {
        position: absolute;
        padding: 0.5rem 1.5rem !important;
        bottom: 0;
        display: flex;
        width: 100%;
        background-color: $white;
      }

      &__left {
        width: 100%;
        display: flex;
        align-items: center;
      }

      &__right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .action-group {
        display: flex;
        justify-content: space-between;

        .btn-primary {
          width: 9rem;
          margin-left: 1em;
        }
      }

      &--bordered {
        display: flex;
        justify-content: space-between;
        display: flex;
        border: solid 1px $gray-4;
        align-items: center;
        padding: 0.5rem;
        background-color: $gray-0 !important;
        text-align: center;
        color: $black;
      }

      .label-container {
        display: flex;
        gap: $base-size;
      }
    }

    tfoot {
      position: sticky;
      bottom: -1px;
      padding: 0;
      background: $gray-1;
      width: 100%;

      tr {
        vertical-align: top;

        // Table data style

        td:last-of-type {
          border-right: 1px solid $gray-4;
        }

        td {
          border-bottom: 0;
          border-right: 1px solid $gray-4;
          vertical-align: middle;
          padding: 0.5rem 0.75rem;
        }
      }
    }
  }

  // Modifier table

  &--shared-table {
    position: relative;
    border-bottom: 0 !important;

    &-sm {
      .table-container__content {
        height: 220px;
        border: 1px solid $gray-4;
        border-bottom: 0 !important;
        padding-bottom: 2px;
        overflow-y: auto;
        overflow-x: unset;
        position: relative;
      }
    }

    .table-container__content {
      height: 230px;
      border: 1px solid $gray-4;
      border-bottom: 0 !important;
      overflow-y: auto;
      overflow-x: unset;
      position: relative;

      table {
        border-collapse: collapse;
      }

      thead {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      &--md {
        height: 370px !important;
      }
    }

    tfoot,
    &-sm tfoot {
      position: sticky;
      bottom: -1px;
      padding: 0;
      background: $gray-1;
      width: 100%;

      tr {
        vertical-align: top;

        // Table data style

        td {
          border-bottom: 0;
          border-right: 1px solid $gray-4;
          vertical-align: middle;
          padding: 0.5rem 0.75rem;

          &:last-of-type {
            border-right: 1px solid $gray-4;
          }
        }
      }
    }
  }

  &--report-table {
    position: relative;

    .table-container__content {
      border: 1px solid $gray-4;
      border-bottom: 1px solid $gray-4;
      position: relative;

      table {
        border-collapse: collapse;
      }
    }

    tfoot {
      position: sticky;
      bottom: -1px;
      padding: 0;
      background: $gray-1;
      width: 100%;

      tr {
        vertical-align: top;

        // Table data style

        td {
          border-bottom: 0;
          border-right: 1px solid $gray-4;
          vertical-align: middle;
          padding: 0.5rem 0.75rem;

          &:last-of-type {
            border-right: 1px solid $gray-4;
          }
        }
      }
    }

    &__header {
      top: 2.5rem !important;
    }
  }

  // Used for large tables with pagination

  &--xl {
    .table-container__content {
      height: calc(100vh - 12.5rem);
      overflow-y: auto;
    }
  }

  &--xxl {
    .table-container__content {
      height: calc(200vh - 15rem);
      border-bottom: 1px solid $gray-4;
      overflow-y: auto;
    }
  }

  // Elements table

  &__wrapper {
    height: 28.571 * $base-size;
    overflow: auto;
    position: relative;
    border: 1px solid $gray-4;
    border-bottom: 0;
  }

  &__footer {
    display: flex;
    border: 1px solid $gray-4;
    align-items: center;
    padding: 0.5rem;

    &--bottom {
      position: absolute;
      padding: 0.5rem 1.5rem !important;
      bottom: 0;
      right: 0;
      display: flex;
      width: 100%;
      background-color: $white;
    }

    &__left {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__center {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__right {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .action-group {
      display: flex;
      justify-content: space-between;

      .btn-primary {
        width: 10rem;
        margin-left: 1em;
      }
    }
  }
}

// Default table style

.table {
  margin-bottom: 0 !important;
  border-collapse: collapse;
  position: relative;

  &__row {
    text-align: center;

    &--average {
      background-color: $gray-0;
      text-align: center;
    }
  }

  &__cell {
    vertical-align: top;

    // Modifier

    &--center {
      text-align: center;
      vertical-align: middle;
    }

    &--left {
      text-align: left !important;
      vertical-align: middle;
    }

    &--right {
      text-align: right !important;
      vertical-align: middle;
    }

    // hover click

    &--clickable {
      &:hover {
        background-color: $brand-primary-opacity-10;
        cursor: pointer;
      }
    }

    // Cell States

    &--red {
      background-color: $red-opacity-10;
    }

    &--green {
      background-color: $green-opacity-10;
    }

    &--gray {
      background-color: $gray-0 !important;
    }
  }

  &__header {
    // Table header style

    th {
      background-color: $brand-primary-0;
      color: $white;
      vertical-align: middle !important;
      font-weight: normal;
      font-size: 12px;
      border: 0;
      border-right: 1px solid $gray-4;
      border-left: 1px solid $brand-primary-0;
      border-top: 0 !important;
      border-bottom: 0 !important;

      // sticky
      padding: 0.5rem !important;
      white-space: nowrap;
      position: sticky !important;
      text-align: center;
      top: 0;
      z-index: 1 !important;

      &:last-of-type {
        border-right: 1px solid $brand-primary-0 !important;
      }
    }

    .table__sort {
      &--orange {
        background-color: $orange;
      }
    }

    // 2nd Table header

    &--report {
      th {
        position: sticky !important;
        text-align: center;
        top: 1.4rem !important;
        z-index: 1 !important;
      }
    }

    &--secondary {
      th {
        background-color: $gray-0 !important;
        text-align: center;
        color: $black;
        border-right: solid 1px $gray-4;
        padding: 0.5rem !important;

        &:first-of-type {
          border-left: 1px solid $white !important;
        }

        &:last-of-type {
          border-right: 1px solid $white !important;
        }
      }
    }
  }

  tr {
    vertical-align: top;

    &:first-of-type {
      td {
        border-top: 0 !important;
      }
    }

    // Table data style

    td {
      border-bottom: 1px solid $gray-4;
      border-right: 1px solid $gray-4;
      border-left: 1px solid $gray-4;
      vertical-align: middle;
      padding: 0.5rem 0.75rem;
      border-top: 0 !important;

      &:last-of-type {
        border-right: 1px solid $gray-4;
      }
    }
  }
}
