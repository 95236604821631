.form-control {
  &:disabled,
  &[readonly] {
    background-color: $gray-2 !important;
    color: $black !important;
  }
}

.operation-buttons,
.clear-btn {
  display: flex;
  gap: 10px;
  padding-bottom: 1rem;
}

.text-area-formula {
  resize: none;
  overflow: auto;
  color: $black;
  height: 15rem !important;
  width: 100%;
}
