.star {
  font-size: 1.5 * $base-size;
  color: #b0c4de;
}

.filled {
  color: #1e90ff;

  &.bad {
    color: #ff1e1e;
  }
}

.bad {
  color: #deb0b0;
}
